import { Link } from 'gatsby';
import React from 'react';
import styled from '@emotion/styled';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Footer from '../components/footer';
import Navigation from '../components/navigation';
import { Container, Section } from '../components/global';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Navigation />
    <Section>
      <Container>
        <h1>NOT FOUND</h1>
        <p>
          Shoot! This page doesn&#39;t exist. Head back{' '}
          <Link to="/" style={{ fontWeight: 'bold' }}>
            Home
          </Link>{' '}
          and see if you can find what you&#39;re looking for there.
        </p>
      </Container>
    </Section>
    <Footer />
  </Layout>
);

export default NotFoundPage;
